import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import instance from "../utils/instance";
import { isAuthenticated, getCurrentDateTime } from "../utils/helpers";

const TrackProductVisits = ({ moqId, userId }) => {
  const [cookies, setCookie] = useCookies(["visited_moqs"]);
  const [tracked, setTracked] = useState(false);
  const authenticated = isAuthenticated();

  useEffect(() => {
    const now = getCurrentDateTime();
    const visit = { moq_id: moqId, timestamp: now };

    const trackVisit = async () => {
      try {
        if (userId === null) return;
        const payload = {
          user_id: userId,
          visited_moqs: [visit],
        };

        // Call the API immediately for logged-in users
        await instance.post("page_track", payload);
        setTracked(true);
      } catch (error) {
        console.log(error)
      }
    };

    // Save product visits in cookies if the user is logged out
    const saveVisit = () => {
      // Get existing visits or initialize an empty array
      let existingVisits = [];

      if (cookies?.visited_moqs && cookies.visited_moqs?.length > 0) {
        existingVisits = [...cookies?.visited_moqs];
      }

      // Check if the moqId already exists in the visited_moqs
      const isMoqVisited = existingVisits.some(
        (visit) => visit.moq_id === moqId
      );

      if (!isMoqVisited) {
        // Save the new visit in cookies only if moqId doesn't already exist
        setCookie("visited_moqs", [...existingVisits, visit], {
          path: "/",
          maxAge: 86400, // Expires in 24 hours
        });
      }
    };

    if (!userId && !authenticated) {
      // User is logged out, track the visit
      saveVisit();
    } else {
      if (!tracked) trackVisit();
    }
  }, [moqId, cookies, setCookie, userId, authenticated, tracked]);

  return null;
};

export default TrackProductVisits;
