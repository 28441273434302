import React, { Component } from "react";
import Layout from "../layouts/Main";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getMoqs,
  getMoreMoqs,
  getActiveMoqs,
  getActiveMoreMoqs,
} from "../store/actions/MoqActions";
import MOQ from "../components/MOQ";
import { toTitleCase } from "./../utils/helpers";
import ContentLoader from "react-content-loader";
import { Helmet } from "react-helmet-async";
import { isAuthenticated } from "../utils/helpers";

const authenticated = isAuthenticated();

const BlockLoader = ({ height = 300, radius = 8, uniqueKey = "unique" }) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    speed={2}
    width={`100%`}
    height={height}
    backgroundColor="#ECEBEB"
    foregroundColor="#b0b0b0"
  >
    <rect
      x="0"
      y="0"
      rx={`${radius}`}
      ry={`${radius}`}
      width="100%"
      height={`${height}`}
    />
  </ContentLoader>
);

class Items extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      page: 0,
      prevY: 0,
      category: 0,
      newest: null,
    };

    this.scrollerRef = React.createRef();

    window.onscroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        // this.fetchMoreMoqs()
      }
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    await this.props.getActiveMoqs();
    this.setState({ loading: false });
  };

  async handleScroll() {
    const scroller = this.scrollerRef.current;
    if (scroller.scrollHeight - scroller.scrollTop === scroller.clientHeight) {
      await this.fetchMoreMoqs();
    }
  }

  fetchMoreMoqs = async () => {
    try {
      this.setState({ loading: true });

      const {
        moqs: { next_page, pages },
      } = this.props;

      if (!next_page) {
        this.setState({ loading: false });
        return;
      }

      if (next_page > pages) {
        this.setState({ loading: false });
        return;
      }

      const result = await this.props.getActiveMoreMoqs(next_page);
      this.setState({ loading: false });

      if (result) {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  render() {
    let {
      moqs: { moqs },
      categories: { data: categories },
    } = this.props;

    const { loading, category, newest } = this.state;
    let moqs_list = [...moqs];

    if (newest === 1) {
      moqs_list = moqs_list?.sort((a, b) => {
        const dateA = new Date(a.date_created).getTime();
        const dateB = new Date(b.date_created).getTime();
        return dateB - dateA;
      });
    }

    return (
      <Layout>
        <Helmet prioritizeSeoTags>
          <meta charSet="utf-8" />

          <title>{`Litudian | MOQ Campaigns`}</title>
          <meta name="title" content="Litudian | MOQ Campaigns" />
          <meta
            name="description"
            content="Make your orders for clothes, electronics, kitchenware, IKEA, bags, footwear, beauty, kids items and more at the best prices from China, UAE, Germany, Turkey"
          />
          <meta name="og:title" content="Litudian | MOQ Campaigns" />
          <meta
            name="og:description"
            content="Make your orders for clothes, electronics, kitchenware, IKEA, bags, footwear, beauty, kids items and more at the best prices from China, UAE, Germany, Turkey"
          />
          <link rel="icon" href="/favicon.ico" />
        </Helmet>

        <h1 className="text-[#A68F69] dark:text-tangerine font-bold uppercase mt-2">
          MOQ Campaigns
        </h1>

        <div className="flex flex-col md:flex-row md:items-center w-full md:space-x-3 py-3">
          <p className="text-[#A68F69] dark:text-gray-200 break-keep mb-2 md:mb-0">
            Filter:
          </p>
          <div className="flex flex-row items-center justify-start relative">
            <select
              name="filter"
              className="px-4 py-2 rounded-sm bg-[#ECEBEB] dark:bg-[#5B5B5B] outline-none dark:text-white"
              onChange={(e) =>
                this.setState({ category: parseInt(e?.target?.value) })
              }
            >
              <option value={0}>All categories</option>
              {categories?.map((c) => (
                <option key={c?.id} value={c?.id}>
                  {toTitleCase(c?.name)}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-row items-center justify-start relative">
            <select
              name="filter"
              className="px-4 py-2 rounded-sm bg-[#ECEBEB] dark:bg-[#5B5B5B] outline-none dark:text-white"
              onChange={(e) =>
                this.setState({ newest: parseInt(e?.target?.value) || null })
              }
              defaultValue={newest}
            >
              <option value={null}>Default order</option>
              <option value={1}>Newest</option>
              <option value={0}>Oldest</option>
            </select>
          </div>
        </div>

        {moqs_list && (
          <div
            ref={this.scrollerRef}
            onScroll={this.handleScroll}
            className="grid grid-cols-2 md:grid-cols-5 gap-4 pt-5"
          >
            {moqs_list
              ?.filter((m) => {
                if (category !== 0 && m?.product && m?.product.length > 0) {
                  return m?.product[0]?.category_id === parseInt(category);
                } else {
                  return m;
                }
              })
              .map((moq, i) => (
                <MOQ key={i} moq={moq} />
              ))}
            {moqs?.filter((m) => {
              if (category !== 0 && m?.product && m?.product.length > 0) {
                return m?.product[0]?.category_id === parseInt(category);
              } else {
                return m;
              }
            })?.length === 0 &&
              !loading && (
                <div className="flex flex-col justify-center items-center h-1/2 col-span-full">
                  <h1 className="text-[#A68F69] dark:text-gray-200">
                    No Products Available
                  </h1>
                </div>
              )}
          </div>
        )}

        {loading && (
          <div className="grid grid-cols-2 md:grid-cols-5 gap-4 py-1 mt-2">
            <BlockLoader uniqueKey="key1" height={250} radius={6} />

            <BlockLoader uniqueKey="key1" height={250} radius={6} />

            <BlockLoader uniqueKey="key1" height={250} radius={6} />

            <BlockLoader uniqueKey="key1" height={250} radius={6} />

            <BlockLoader uniqueKey="key1" height={250} radius={6} />
          </div>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    moqs: state.moqs,
    categories: state.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getMoqs, getMoreMoqs, getActiveMoqs, getActiveMoreMoqs },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Items);
