import React, { useState, useEffect } from "react";
import instance from "../utils/instance";
import { registerGtagEvent } from "../utils/helpers";
import { toast } from "react-toastify";
import Layout from "./../layouts/Main";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createBid, getUserBids } from "./../store/actions/BidActions";
import { showUser } from "./../store/actions/UserActions";
import { Helmet } from "react-helmet-async";
import ProgressBar from "@ramonak/react-progress-bar";
import { AuthModalContext } from "./../contexts/auth";
import { isAuthenticated } from "../utils/helpers";

const authenticated = isAuthenticated();

function Cart(props) {
  let {
    bids: { data: bids },
  } = props;

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [moqId, setMoqId] = useState(0);

  useEffect(() => {
    async function fetchUserBids() {
      if (authenticated) {
        await props.getUserBids();
      }
    }

    fetchUserBids();

    let data = getBidsFromCard();

    setOrders(data.reverse());
  }, []);

  const getBidsFromCard = () => {
    let data = JSON.parse(localStorage.getItem("bids")) || [];

    return data;
  };

  const removeBidFromCard = (id) => {
    const data = getBidsFromCard();

    let filtered = data.filter((bid) => bid?.moq_id !== id);

    localStorage.setItem("bids", JSON.stringify(filtered));
  };

  const makeOrder = async (id) => {
    try {
      if (!authenticated) {
        return toast.error("Please login to make an order !", {
          autoClose: 10000,
        });
      }

      let order = orders.find((bid) => bid?.moq_id === id);

      if (!order) {
        return toast.error("Could not complete order. Please try again !", {
          autoClose: 10000,
        });
      }

      setLoading(true);

      let uid = localStorage.getItem("ltdn_uid");

      let userData = await props?.showUser(uid);

      delete order.moq;

      delete order.product;

      delete order.id;

      order.msisdn = userData?.msisdn;

      order.send_sms = 1;

      const result = await props?.createBid(order);

      if (result) {
        setLoading(false);

        setMoqId(0);

        let filtered = orders.filter((bid) => bid?.moq_id !== id);

        setOrders(filtered);

        removeBidFromCard(id);

        toast.success(
          "Order Placed Successfully !",
          {
            position: toast.POSITION.TOP_RIGHT,
          },
          { autoClose: 15000 }
        );
        registerGtagEvent("order_button_click");
        registerGtagEvent("conversion");

        return (window.location.href = `/items/${result?.id}`);
      }
    } catch (error) {
      setLoading(false);

      if (
        error &&
        error?.response?.data &&
        error?.response?.data !== undefined &&
        error?.response?.data?.message !== undefined
      ) {
        return toast.error(`${error.response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      toast.error("Error making your order. Please try again later!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const getTotal = (order) => {
    let sum = 0;

    if (
      order?.product &&
      order?.product?.varying_sizes === 1 &&
      order?.bid_attributes &&
      order?.moq &&
      order?.moq?.moq_attributes
    ) {
      order?.bid_attributes.map((attr) => {
        let price = 0;
        if (
          order?.moq?.moq_attributes?.find((a) => a?.size_id === attr?.size_id)
            ?.below_moq_price > 0
        ) {
          price =
            order?.moq?.moq_attributes?.find(
              (a) => a?.size_id === attr?.size_id
            )?.below_moq_price || 0;
        } else {
          price =
            order?.moq?.moq_attributes?.find(
              (a) => a?.size_id === attr?.size_id
            )?.price || 0;
        }

        // let price = order?.moq?.moq_attributes?.find((a) => a?.size_id === attr?.size_id)?.price || 0

        let total = price * attr?.quantity;

        sum += total;
      });
    }

    return sum;
  };

  const getPercentage = (total_bid_quantity, quantity) => {
    let totals = total_bid_quantity || 0;

    let percentage = !isNaN(Math.trunc((totals * 100) / quantity))
      ? Math.trunc((totals * 100) / quantity)
      : 0;

    return percentage;
  };

  return (
    <AuthModalContext.Consumer>
      {({ toggleModal }) => (
        <Layout>
          <Helmet prioritizeSeoTags>
            <meta charSet="utf-8" />

            <title>{`Litudian | Cart`}</title>

            <link rel="icon" href="/favicon.ico" />
          </Helmet>

          <div className="flex flex-col md:flex-row justify-between items-start md:items-center pt-9 space-y-4 md:space-y-0">
            <div className="flex flex-row items-center justify-between space-x-4 md:space-x-20">
              <h1 className="text-xl text-[#A68F69] dark:text-tangerine capitalize font-bold">
                Cart
              </h1>

              {/* <div className='flex flex-row justify-start items-center space-x-2'>

                            <span className={`${moqFull === 1 ? 'bg-[#FFBF00]' : 'bg-[#A68F69]'} text-white text-sm px-4 pb-[2px] rounded-full capitalize shadow w-fit cursor-pointer`} onClick={() => setMoqFull(1)}>Ready MOQ</span>

                            <span className={`${moqFull === 0 ? 'bg-[#FFBF00]' : 'bg-[#A68F69]'} text-white text-sm px-4 pb-[2px] rounded-full capitalize shadow w-fit cursor-pointer`} onClick={() => setMoqFull(0)}>Pending MOQ</span>

                        </div> */}
            </div>

            {authenticated === true && orders.length > 0 && (
              <button
                type="button"
                className="bg-tangerine rounded-full text-white py-3 px-4 make-order-3"
                onClick={async () => {
                  let data = orders.map((order) => {
                    order.send_sms = 1;
                    delete order.moq;
                    delete order.product;
                    delete order.id;
                    return order;
                  });

                  try {
                    if (data.length === 0) {
                      return toast.error(
                        "Cannot proceed with order when the cart is empty !",
                        {
                          autoClose: 10000,
                        }
                      );
                    }
                    let response = await instance.post("bid", data);

                    if (response?.data) {
                      toast.success("Order Placed Successfully!", {
                        autoClose: 10000,
                      });
                      registerGtagEvent("order_button_click");

                      localStorage.removeItem("bids");

                      return (window.location.href = "/orders");
                    }
                  } catch (error) {
                    if (
                      error?.response?.data &&
                      error?.response?.data?.message
                    ) {
                      toast.error(`${error?.response?.data?.message}`, {
                        autoClose: 10000,
                      });
                    } else {
                      toast.error("Error creating your order. Please try again later!", {
                        autoClose: 10000,
                      });
                    }
                  }
                }}
              >
                Proceed with Order
              </button>
            )}

            {authenticated === false && (
              <button
                type="button"
                className="bg-tangerine rounded-lg text-white py-1 px-4"
                onClick={() => toggleModal()}
              >
                Login to proceed with order
              </button>
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-5 mb-10">
            {orders.map((order, i) => {
              let findBid = bids.find((bid) => bid?.moq_id === order?.moq_id);

              let bgColor =
                getPercentage(
                  order?.moq?.total_bid_quantity,
                  order?.moq?.quantity
                ) < 100
                  ? "#FFBF00"
                  : "#8DC63F";

              return (
                <div
                  key={i}
                  className="grid grid-cols-7 border border-[#E0E0E0] dark:border-[#5B5B5B] rounded-[10px] bg-[#E0E0E0] dark:bg-[#5B5B5B]"
                >
                  <div
                    className="col-span-3 rounded-l-[10px] h-full flex-1 flex flex-col cursor-pointer"
                    onClick={() =>
                      (window.location.href = `/products/${order?.moq_id}`)
                    }
                  >
                    <img
                      className="h-full object-cover w-full rounded-l-[10px]"
                      src={order?.product?.images[0]}
                      alt={"product"}
                      onError={(e) => {
                        e.target.src = "https://via.placeholder.com/300";
                      }}
                    />
                  </div>

                  <div className="col-span-4 h-full w-full bg-[#fff] dark:bg-[#5B5B5B] rounded-r-[10px] flex flex-col p-4 space-y-2">
                    <a href={`/products/${order?.moq_id}`}>
                      <p className="text-[#5B5B5B] dark:text-white text-md w-full truncate break-words capitalize font-normal">
                        {order?.product?.name}
                      </p>
                    </a>

                    <div className="flex flex-row space-x-1">
                      <div className="flex flex-col flex-1">
                        <p className="text-[#5B5B5B] dark:text-white  text-xs w-full truncate break-words capitalize font-normal">
                          Number of Items
                        </p>

                        <h1 className="text-[#5B5B5B] dark:text-white ">
                          {order?.quantity}
                        </h1>
                      </div>

                      <div className="flex flex-row items-center space-x-1">
                        <button
                          type="button"
                          className="p-[5px] bg-[#FAA51A] rounded-lg text-white h-fit"
                          onClick={() =>
                            (window.location.href = `/products/${order?.moq?.id}`)
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                            />
                          </svg>
                        </button>

                        <button
                          type="button"
                          className="p-[5px] bg-[#FAA51A] rounded-lg text-white h-fit"
                          onClick={() => {
                            removeBidFromCard(order?.moq_id);
                            const data = getBidsFromCard();
                            setOrders(data.reverse());
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div className="flex flex-col flex-1">
                      <p className="text-[#5B5B5B] dark:text-white text-xs w-full truncate break-words capitalize font-normal">
                        Total Cost
                      </p>

                      {order?.product?.varying_sizes === 1 ? (
                        <h1 className="text-[#5B5B5B] dark:text-white text-xl">
                          KES {getTotal(order)?.toLocaleString() || 0}
                        </h1>
                      ) : (
                        <h1 className="text-[#5B5B5B] dark:text-white text-xl">
                          KES{" "}
                          {order?.moq?.below_moq_price > 0
                            ? (
                                order?.moq?.below_moq_price * order?.quantity
                              ).toLocaleString() || 0
                            : (
                                order?.moq?.price * order?.quantity
                              ).toLocaleString() || 0}
                        </h1>
                      )}
                    </div>

                    <div className="flex flex-row flex-1 w-full items-center">
                      <ProgressBar
                        completed={getPercentage(
                          order?.moq?.total_bid_quantity,
                          order?.moq?.quantity
                        )}
                        className="w-full"
                        bgColor={bgColor}
                        height="16px"
                        labelAlignment="left"
                        labelClassName={`text-white text-[12px] px-1 min-w-[100px] block`}
                        customLabel={`${getPercentage(
                          order?.moq?.total_bid_quantity,
                          order?.moq?.quantity
                        )}% full`}
                      />
                    </div>

                    {authenticated && !findBid && (
                      <button
                        type="button"
                        className="px-[10px] py-1 bg-tangerine rounded text-white h-fit make-order-2"
                        onClick={() => {
                          setMoqId(order?.moq_id);
                          makeOrder(order?.moq_id);
                        }}
                      >
                        {loading === true && moqId === order?.moq_id
                          ? "Wait..."
                          : "Place Order"}
                      </button>
                    )}

                    {authenticated && findBid && (
                      <button
                        type="button"
                        className="px-[10px] py-1 bg-tangerine rounded text-white h-fit"
                        onClick={() =>
                          (window.location.href = `/items/${findBid?.id}`)
                        }
                      >
                        View Order
                      </button>
                    )}
                  </div>
                </div>
              );
            })}

            {orders.length === 0 && (
              <div className="col-span-full flex flex-col justify-center items-center w-full min-h-[400px]">
                <h1 className="text-[#A68F69] dark:text-tangerine">
                  Cart Empty
                </h1>
              </div>
            )}
          </div>
        </Layout>
      )}
    </AuthModalContext.Consumer>
  );
}

const mapStateToProps = (state) => {
  return {
    bids: state.bids,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createBid, showUser, getUserBids }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
