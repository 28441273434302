import React, { useEffect, useState } from "react";
import instance from "../utils/instance";
import { useCookies } from "react-cookie";

const AuthenicatedUserProductVisitsHandler = ({ userId }) => {
  const [cookies, removeCookie] = useCookies(["visited_moqs"]);
  const [tracked, setTracked] = useState(false)

  useEffect(() => {
    const sendTrackedData = async () => {
      if (cookies.visited_moqs && cookies.visited_moqs?.length > 0 && !tracked) {
        try {
          // Prepare payload
          const payload = {
            user_id: userId,
            visited_moqs: cookies.visited_moqs,
          };

          // Make POST request to the API
          await instance.post("page_track", payload);
          setTracked(true)

          // Clear cookies after successful request
          removeCookie("visited_moqs", { path: "/" });
        } catch (error) {
          console.error("Error sending tracked data:", error);
        }
      }
    };

    // User is logged in, send tracked data
    if (userId && typeof window !== "undefined" && !window?.location?.pathname.includes('products')) {
      sendTrackedData();
    }
  }, [userId, cookies, removeCookie, tracked]);

  return null;
};

export default AuthenicatedUserProductVisitsHandler;
