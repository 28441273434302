import React, { useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import { AuthModalContext } from "./../contexts/auth";
import NotAuthenticated from "../pages/NotAuthenticated";
import { isAuthenticated } from "../utils/helpers";

const ProtectedRoute = () => {
  const authenticated = isAuthenticated();

  // contexts
  const { toggleModal } = useContext(AuthModalContext);

  //Effects
  useEffect(() => {
    if (!authenticated) {
      toggleModal();
    }
  }, []);

  return authenticated ? <Outlet /> : <NotAuthenticated />;
};

export default ProtectedRoute;
