import React, { Component, Fragment } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import { toast } from "react-toastify";
import instance from "./../utils/instance";
import { registerGtagEvent } from "../utils/helpers";
import { Link } from "react-router-dom";
import { AuthModalContext } from "./../contexts/auth";
import { withCookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

class Auth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      msisdn: "",

      password: "",

      loading: false,

      first_name: "",

      last_name: "",

      email: "",

      confirm_password: "",

      username: "",

      login_username: "",
    };
  }

  classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  login = async () => {
    try {
      this.setState({ loading: true });

      const { login_username, password } = this.state;

      if (login_username === "" || password === "") {
        return toast.error("Please fill all the fields !", {
          position: "bottom-center",
        });
      }

      let { data } = await instance.post("users/login", {
        username: login_username,
        password,
      });

      localStorage.setItem("ltdn_uid", data.id);
      localStorage.setItem("ltdn_points", data?.points);

      // Setting token in cookie
      const { cookies } = this.props;
      
      const decoded = jwtDecode(data?.token, { header: true });
      // Executes if the expiry is decoded from the token and is present
      if (decoded?.exp) {
        const expirationTime = new Date(decoded?.exp * 1000);
        // Setting the token in the cookie with the correct expirty time
        cookies.set("ltdn", data.token, {
          expires: expirationTime,
        });
        // Setting the token expirty time in the cookie
        cookies.set("ltdn_exp", decoded?.exp, {
          expires: expirationTime,
        });
      }

      toast.success(`You have logged in successfully`, {
        autoClose: 10000,
        position: "bottom-center",
      });

      registerGtagEvent("user_login");

      this.setState({ loading: false });

      // Send a message through the Broadcast Channel API
      const broadcastChannel = new BroadcastChannel("LitudianChannel");

      broadcastChannel.postMessage("loginSuccess");

      // return (window.location.href = "/");
    } catch (error) {
      this.setState({ loading: false });

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast(`${error.response.data.message}`, { position: "bottom-center" });
      } else {
        toast("Invalid login credentials", { position: "bottom-center" });
      }
    }
  };

  register = async () => {
    try {
      this.setState({ loading: true });

      const {
        first_name,
        last_name,
        msisdn,
        email,
        password,
        confirm_password,
        username,
      } = this.state;

      if (password !== confirm_password) {
        return toast.error("Passwords do not match !", {
          position: "bottom-center",
        });
      }

      if (email === "") {
        return toast.error("Please provide Email Address", {
          position: "bottom-center",
        });
      }

      await instance.post("users", {
        first_name,
        last_name,
        username: username !== "" ? username : first_name,
        msisdn,
        email,
        password,
        confirm_password,
      });

      toast.success(`You have registered in successfully !`, {
        autoClose: 10000,
        position: "bottom-center",
      });
      registerGtagEvent("user_register");

      this.setState({ loading: false });

      return (window.location.href = "/");
    } catch (error) {
      this.setState({ loading: false });

      if (error.response && error.response.data !== "") {
        if (error?.response?.status === 409) {
          return toast.error(
            "Information you have provided already exists. Please login or click on forgot password to reset your account !",
            { autoClose: 10000, position: "bottom-center" }
          );
        }

        toast.error(error.response.data.message, {
          autoClose: 10000,
          position: "bottom-center",
        });
      } else {
        toast.error("An error occurred, please try again later !", {
          autoClose: 10000,
          position: "bottom-center",
        });
      }
    }
  };

  render() {
    const { loading } = this.state;

    const { logo, toggleForgotPasswordModal } = this.props;

    return (
      <AuthModalContext.Consumer>
        {({ showModal, toggleModal }) => (
          <Transition appear show={showModal} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-50 overflow-y-auto"
              onClose={toggleModal}
            >
              <div className="min-h-screen px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-80" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>

                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <div className="flex flex-col justify-center items-center py-6">
                      <img
                        className="h-12 object-contain"
                        src={logo}
                        alt="logo"
                      />
                    </div>

                    <Tab.Group>
                      <Tab.List className="flex space-x-1 rounded-xl px-7">
                        <Tab
                          key="login"
                          className={({ selected }) =>
                            this.classNames(
                              "w-full py-2.5 text-sm leading-5 font-medium rounded-t-xl uppercase",

                              selected
                                ? "bg-tangerine text-white focus:outline-none"
                                : "text-tangerine hover:bg-white/[0.12] hover:text-litudian-orange"
                            )
                          }
                        >
                          Login
                        </Tab>

                        <Tab
                          key="register"
                          className={({ selected }) =>
                            this.classNames(
                              "w-full py-2.5 text-sm leading-5 font-medium rounded-t-xl uppercase",

                              selected
                                ? "bg-tangerine text-white focus:outline-none"
                                : "text-tangerine hover:bg-white/[0.12] hover:text-litudian-orange"
                            )
                          }
                        >
                          Register
                        </Tab>
                      </Tab.List>

                      <Tab.Panels className="mt-0">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            this.login();
                          }}
                        >
                          <Tab.Panel
                            key="login"
                            className={this.classNames(
                              "bg-tangerine rounded-xl py-10 flex flex-col px-10 md:px-20 gap-y-5"
                            )}
                          >
                            <input
                              name="login_username"
                              type="text"
                              placeholder="Email/Phone Number"
                              className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none normal-case text-center"
                              onChange={this.handleChange}
                              value={this.state.login_username}
                            />

                            <input
                              name="password"
                              type="password"
                              placeholder="Password"
                              className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none normal-case text-center"
                              onChange={this.handleChange}
                              value={this.state.password}
                            />

                            <button
                              className="bg-litudian-orange text-white w-full py-2 font-bold rounded-md uppercase user-login"
                              type="submit"
                            >
                              {loading ? "Waiting ..." : "Login"}
                            </button>

                            <button
                              type="button"
                              onClick={() => {
                                toggleModal();
                                toggleForgotPasswordModal(true);
                              }}
                            >
                              Forgot Password ?{" "}
                              <span className="text-white">Click Here</span>
                            </button>

                            <p className="text-white capitalize text-center">
                              By clicking LOGIN you agree with our terms of
                              service stipulated{" "}
                              <Link to={"/terms"} className="text-gray-800">
                                HERE
                              </Link>
                            </p>
                          </Tab.Panel>
                        </form>

                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            this.register();
                          }}
                        >
                          <Tab.Panel
                            key="register"
                            className={this.classNames(
                              "bg-tangerine rounded-xl py-10 flex flex-col px-10 md:px-20 gap-y-5"
                            )}
                          >
                            <input
                              name="first_name"
                              type="text"
                              placeholder="First Name"
                              className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none normal-case text-center"
                              onChange={this.handleChange}
                              value={this.state.first_name}
                              required
                            />

                            <input
                              name="last_name"
                              type="text"
                              placeholder="Last Name"
                              className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none normal-case text-center"
                              onChange={this.handleChange}
                              value={this.state.last_name}
                              required
                            />

                            <input
                              name="username"
                              type="text"
                              placeholder="Company Name (Optional)"
                              className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none normal-case text-center"
                              onChange={this.handleChange}
                              value={this.state.username}
                            />

                            <input
                              name="email"
                              type="email"
                              placeholder="Email"
                              className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none normal-case text-center"
                              onChange={this.handleChange}
                              value={this.state.email}
                              required
                            />

                            <input
                              name="msisdn"
                              type="text"
                              placeholder="Phone Number"
                              className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none normal-case text-center"
                              onChange={this.handleChange}
                              value={this.state.msisdn}
                              required
                            />

                            <input
                              name="password"
                              type="password"
                              placeholder="Password"
                              className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none normal-case text-center"
                              onChange={this.handleChange}
                              value={this.state.password}
                              required
                            />

                            <input
                              name="confirm_password"
                              type="password"
                              placeholder="Confirm Password"
                              className="py-2 px-7 placeholder-gray-400 font-bold shadow-sm rounded-md focus:outline-none normal-case text-center"
                              onChange={this.handleChange}
                              value={this.state.confirm_password}
                              required
                            />

                            <button
                              className="bg-litudian-orange text-white w-full py-2 font-bold rounded-md uppercase user-register"
                              type="submit"
                            >
                              {loading ? "waiting..." : "Create Account"}
                            </button>

                            <p className="text-white capitalize text-center">
                              By clicking REGISTER you agree with our terms of
                              service stipulated{" "}
                              <Link to={"/terms"} className="text-gray-800">
                                HERE
                              </Link>
                            </p>
                          </Tab.Panel>
                        </form>
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
        )}
      </AuthModalContext.Consumer>
    );
  }
}

export default withCookies(Auth);
