import React, { Component } from "react";
import Carousel from "./../components/Slider";
import Layout from "./../layouts/Main";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getRecentMoqs } from "./../store/actions/MoqActions";
import { getCategories } from "./../store/actions/CategoryActions";
import { getRecentSearches } from "./../store/actions/RecentSearchActions";
import CategoryMOQsCard from "./../components/CategoryMOQsCard";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import instance from "../utils/instance";
import { Helmet } from "react-helmet-async";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BlockLoader = ({ height = 300, radius = 8, uniqueKey = "unique" }) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    speed={2}
    width={`100%`}
    height={height}
    backgroundColor="#ECEBEB"
    foregroundColor="#b0b0b0"
  >
    <rect
      x="0"
      y="0"
      rx={`${radius}`}
      ry={`${radius}`}
      width="100%"
      height={`${height}`}
    />
  </ContentLoader>
);

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      categories_loading: true,
      promotions: [],
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });

    await this.props.getRecentMoqs();

    await this.props.getRecentSearches();

    await this.getPromotions();

    await this.fetchCategories();

    this.setState({ loading: false });
  };

  getPromotions = async () => {
    try {
      let result = await instance.get("home_promotions");

      if (result?.data?.promotions) {
        this.setState({ promotions: result?.data?.promotions });
      }
    } catch (e) {
      console.log(e);
    }
  };

  fetchCategories = async () => {
    try {
      this.setState({ categories_loading: true });
      await this.props.getCategories();
      this.setState({ categories_loading: false });
    } catch (e) {
      console.log(e);
      this.setState({ categories_loading: false });
    }
  };

  render() {
    const {
      categories: { data },
      recent_searches: { data: searches },
      moqs: { recent_moqs },
    } = this.props;

    const { loading, promotions, categories_loading } = this.state;

    return (
      <Layout>
        <Helmet prioritizeSeoTags>
          <meta charSet="utf-8" />

          <title>{`Litudian | Kenya's Top Sourcing Platform`}</title>

          <link rel="icon" href="/favicon.ico" />
        </Helmet>

        <div className="grid grid-cols-1 md:grid-cols-4 md:gap-4 py-5">
          <div className="flex flex-col mb-8 md:mb-0">
            <h1 className="text-[#A68F69] dark:text-tangerine font-bold uppercase">
              Recent Campaigns
            </h1>

            {loading && (
              <div className="flex flex-col space-y-2 mt-1">
                <BlockLoader height={80} radius={6} />

                <BlockLoader height={80} radius={6} />

                <BlockLoader height={80} radius={6} />
              </div>
            )}

            {!loading &&
              recent_moqs.map((moq, i) => (
                <Link key={i} to={`/products/${moq?.moq_slug || moq?.id}`}>
                  <div
                    key={i}
                    className="bg-[#ECEBEB] dark:bg-[#5B5B5B] w-full h-20 mt-2 rounded-md shadow flex flex-row justify-start items-center px-5 cursor-pointer space-x-5"
                  >
                    <LazyLoadImage
                      className="w-14 h-14"
                      src={
                        moq?.product &&
                        moq?.product?.length > 0 &&
                        moq?.product[0]?.images.length
                          ? moq?.product[0]?.images[0]
                          : "https://via.placeholder.com/300"
                      }
                      alt={moq?.product_name?.toLowerCase()}
                    />

                    <p className="font-bold capitalize text-base text-gray-500 dark:text-white truncate">
                      {moq?.product[0]?.name?.toLowerCase()}
                    </p>
                  </div>
                </Link>
              ))}
          </div>

          <div className="col-span-2">
            {loading && (
              <BlockLoader
                uniqueKey="carousel"
                height={288}
                width={"100%"}
                radius={6}
              />
            )}

            {!loading && <Carousel promotions={promotions} />}
          </div>

          <div className="hidden md:flex flex-col">
            <h1 className="text-[#A68F69] dark:text-tangerine font-bold uppercase">
              Recent Searches
            </h1>

            {loading && (
              <div className="flex flex-col space-y-2 mt-1">
                <BlockLoader height={80} radius={6} />

                <BlockLoader height={80} radius={6} />

                <BlockLoader height={80} radius={6} />
              </div>
            )}

            {!loading &&
              searches.length >= 3 &&
              [...searches].splice(0, 3).map((search, i) => (
                <Link
                  key={i}
                  to={`/products/${search?.moq_slug || search?.moq_id}`}
                >
                  <div
                    key={i}
                    className="bg-[#ECEBEB] dark:bg-[#5B5B5B] w-full h-20 mt-2 rounded-md shadow flex flex-row justify-start items-center px-5 cursor-pointer space-x-5"
                  >
                    <LazyLoadImage
                      className="w-14 h-14"
                      src={
                        search?.product_images &&
                        search?.product_images?.length > 0
                          ? search?.product_images[0]
                          : "https://via.placeholder.com/300"
                      }
                      alt={search?.product_name?.toLowerCase()}
                    />

                    <p className="font-bold capitalize text-base text-gray-500 dark:text-white truncate">
                      {search?.product_name?.toLowerCase()}
                    </p>
                  </div>
                </Link>
              ))}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-5">
          {categories_loading && (
            <BlockLoader uniqueKey="key1" height={350} radius={6} />
          )}

          {categories_loading && (
            <BlockLoader uniqueKey="key1" height={350} radius={6} />
          )}

          {categories_loading && (
            <BlockLoader uniqueKey="key1" height={350} radius={6} />
          )}

          {categories_loading && (
            <BlockLoader uniqueKey="key1" height={350} radius={6} />
          )}

          {!categories_loading &&
            data
              // ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((category, i) => (
                <CategoryMOQsCard key={i} category={category} />
              ))}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.categories,

    recent_searches: state.recent_searches,

    moqs: state.moqs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getCategories, getRecentSearches, getRecentMoqs },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
