import React, { Component } from "react";
import withRouter from "../utils/withRouter";
import Layout from "./../layouts/Main";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCategoryById } from "./../store/actions/CategoryActions";
import { getMoqsByCategoryId } from "./../store/actions/MoqActions";
import MOQ from "./../components/MOQ";
import instance from "../utils/instance";
import ContentLoader from "react-content-loader";
import { Helmet } from "react-helmet-async";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BlockLoader = ({
  height = 300,
  width = "100%",
  radius = 8,
  uniqueKey = "unique",
}) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    speed={2}
    width={width}
    height={height}
    backgroundColor="#ECEBEB"
    foregroundColor="#b0b0b0"
  >
    <rect
      x="0"
      y="0"
      rx={`${radius}`}
      ry={`${radius}`}
      width="100%"
      height={`${height}`}
    />
  </ContentLoader>
);

const settings = {
  dots: true,

  autoplay: true,

  infinite: true,

  speed: 500,

  slidesToShow: 1,

  slidesToScroll: 1,

  arrows: false,

  appendDots: (dots) => (
    <div
      style={{
        position: "absolute",

        bottom: "10px",

        borderRadius: "10px",

        padding: "10px",
      }}
    >
      <ul style={{ margin: "0px" }}> {dots} </ul>
    </div>
  ),
};

class Categories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      promotion: null,

      promotions: [],

      data: [],
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });

    const {
      params: { id },
    } = this.props;

    const response = await this.props.getCategoryById(id);

    if (response) {
      await this.props.getMoqsByCategoryId(response?.id);

      await this.getMoqs(response?.id);
    }

    await this.getPromotions();

    this.setState({ loading: false });
  };

  getPromotions = async (id) => {
    try {
      let result = await instance.get(`category_promotions`);

      if (result?.data) {
        this.setState({ promotions: result?.data?.promotions });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getMoqs = async (id) => {
    try {
      const response = await instance.get(`moq/category/${id}`);

      let data = [];

      if (response.data.moqs) {
        data = response.data.moqs || [];

        this.setState({ data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      categories: { category },
    } = this.props;

    const { loading, data, promotions } = this.state;

    let promos =
      promotions.filter(
        (promo) => promo?.category_id === parseInt(category?.id)
      ) || [];

    return (
      <Layout>
        <Helmet prioritizeSeoTags>
          <meta charSet="utf-8" />

          <title>{`Litudian ${
            category && typeof category !== "object" && category.name !== null
              ? ` | ${category.name}`
              : ""
          }`}</title>
          <meta name="description" content={`${category?.name}`}></meta>

          <link rel="canonical" href={`https://www.litudian.com/categories/${category?.slug}`}></link>

          <link rel="icon" href="/favicon.ico" />
        </Helmet>

        <div className="flex flex-col">
          {loading && (
            <BlockLoader
              uniqueKey="carousel"
              height={400}
              width={"100%"}
              radius={6}
            />
          )}

          {!loading && promos && promos.length > 0 && (
            <Slider {...settings}>
              {promos.map((promo, i) => (
                <div key={i} className="h-[400px]">
                  <div className="flex flex-col relative h-full">
                    <img
                      className="w-full object-cover h-full"
                      src={promo.image_url}
                      alt={promo.title}
                    />

                    <div className="absolute top-0 left-0 bg-black/10 rounded-md flex flex-1 flex-col justify-end items-center w-full p-10 h-full space-y-4">
                      <h3 className="text-white text-4xl font-bold">
                        {promo.title}
                      </h3>

                      <p className="text-white text-lg font-bold">
                        {promo.description}
                      </p>
                    </div>

                    {promo?.item_url && promo?.item_url !== "" && (
                      <div
                        onClick={() =>
                          window.location.href === `${promo?.item_url}`
                        }
                        className="absolute top-0 left-0 bg-transparent z-10 rounded-md flex flex-1 flex-col justify-end items-center w-full p-10 h-full space-y-4 cursor-pointer"
                      ></div>
                    )}
                  </div>
                </div>
              ))}
            </Slider>
          )}

          <h1 className="text-[#A68F69] dark:text-tangerine font-bold uppercase mt-5">
            {category !== null && category.name}
          </h1>

          <div className="grid grid-cols-2 md:grid-cols-5 gap-4 py-5">
            {loading && (
              <BlockLoader uniqueKey="key1" height={250} radius={6} />
            )}

            {loading && (
              <BlockLoader uniqueKey="key1" height={250} radius={6} />
            )}

            {loading && (
              <BlockLoader uniqueKey="key1" height={250} radius={6} />
            )}

            {loading && (
              <BlockLoader uniqueKey="key1" height={250} radius={6} />
            )}

            {loading && (
              <BlockLoader uniqueKey="key1" height={250} radius={6} />
            )}

            {!loading && data.map((moq, i) => <MOQ key={i} moq={moq} />)}
          </div>

          {!loading && data.length === 0 && (
            <div className="flex flex-col justify-center items-center w-full min-h-[200px]">
              <h1 className="text-[#A68F69] dark:text-tangerine">
                No Products Available
              </h1>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    moqs: state.moqs,

    categories: state.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getMoqsByCategoryId, getCategoryById }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Categories));
